<template>
  <div class="privilege">
    <div class="title">
      已获得声望特权
    </div>
    <div class="line" />
    <div class="pri-content">
      <div
        v-for="(item,index) in privilegeInfo"
        :key="index"
        class="pri-item"
      >
        <div class="pri-icon">
          <img
            :src="item.icon"
            alt=""
          >
          <i
            v-if="item.msg"
            class="sub-icon"
          >{{ item.msg }}</i>
        </div>
        <div class="pri-text">
          {{ item.name }}
        </div>
      </div>
    </div>
    <v-btn-jump class="privilege-btn-jump" />
  </div>
</template>

<script>
import VBtnJump from '@/page/embed-page/download-in-app/component/btn-jump/index.vue';
import { logEvent } from '../server';

export default {
  name: 'Privilege',
  components: { VBtnJump },
  props: {
    privilegeInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isReport: false,
    };
  },

  methods: {
    report() {
      if (!this.isReport) {
        logEvent({ eventType: 2, extendId: 1, operId: '1901000010201' });
        this.isReport = true;
      }
      return true;
    },
  },
};
</script>

<style>
</style>
