<template>
  <div :class="['h5-container', 'container-orientation-'+orientation]">
    <UserInfo
      :user-info="pageData.userInfo"
      @clickTips="ruleDialogShow = !ruleDialogShow"
    />
    <div class="left-fixed">
      <div
        v-if="giftList.length || signGiftList.length"
        :class="{active:active==0}"
        @click="active=0"
        class="tab"
      >
        <div>
          <i class="icon icon-1" />
          <span>福利</span>
        </div>
      </div>
      <div
        :class="{active:active==1}"
        @click="active=1"
        class="tab"
      >
        <div>
          <i class="icon icon-2" />
          <span>我的声望</span>
        </div>
      </div>
      <div
        :class="{active:active==2}"
        @click="active=2"
        class="tab"
      >
        <div>
          <i class="icon icon-3" />
          <span>声望特权</span>
        </div>
      </div>
    </div>

    <div class="right-container">
      <div
        id="right-wrap"
        class="right-wrap"
      >
        <!--签到抽奖-->
        <v-sign-gift
          v-if="signGiftList.length"
          :group-id="groupId"
          :gift-list="signGiftList"
        />

        <!--礼包兑换-->
        <v-card-gift
          v-if="giftList.length || signGiftList.length"
          :class="{hide:active!=0, 'no-gift-list': !giftList.length}"
          :group-id="groupId"
          :gift-list="giftList"
          :sign-gift-list="signGiftList"
        />

        <!--声望值-->
        <CardSwiper
          v-if="pageData.rankInfo && pageData.userInfo"
          :class="{hide:active!=1}"
          :show="active==1"
          :rank-info="pageData.rankInfo"
          :user-info="pageData.userInfo"
        />

        <!--banner-->
        <v-banner />

        <!--特权-->
        <Privilege
          :class="{hide:active!=2}"
          :privilege-info="pageData.privilegeInfo"
          :user-info="pageData.userInfo"
        />
      </div>
    </div>

    <v-rule
      v-model="ruleDialogShow"
      :rule="pageData.upRule"
    />

    <div class="page-btn-jump">
      <v-btn-jump />
    </div>
  </div>
</template>
<script>
// import cookie from 'js-cookie';

import './source/fixable';
import VRule from '@/page/embed-page/download-in-app/component/rule.vue';
import VCardGift from '@/page/embed-page/download-in-app/component/card-gift.vue';
import VBanner from '@/page/embed-page/download-in-app/component/banner.vue';
import VBtnJump from '@/page/embed-page/download-in-app/component/btn-jump/index.vue';
import VSignGift from '@/page/embed-page/download-in-app/component/sign-gift.vue';
import CardSwiper from './component/card-swiper.vue';
import Privilege from './component/privilege.vue';
import UserInfo from './component/userinfo.vue';
import './component/swiper/index';
import './component/swiper/swiper-3.4.2.min.css';
import {
  getGiftList, getUserInfo, getSignGifts, getGroupId, logEvent, autoJumpGameCode,
} from './server';


// 子项目
export default {
  components: {
    VSignGift,
    VCardGift,
    VBanner,
    VBtnJump,
    VRule,
    CardSwiper,
    Privilege,
    UserInfo,
  },
  data() {
    return {
      active: 1,
      orientation: 0,
      ruleDialogShow: false,
      groupId: 0,
      pageData: {},
      giftList: [],
      signGiftList: [],
      reportMap: {
        0: 2,
        1: 0,
        2: 1,
      },
    };
  },
  watch: {
    active(n) { // 0  1 2
      const id = 3 + this.reportMap[n]; // 上报事件id 3 4 5
      logEvent({ eventType: 3, extendId: id, operId: `190100001030${id}` });
    },
  },
  async created() {
    this.init();
    [this.groupId, this.signGiftList, this.giftList] = await Promise.all([
      getGroupId(),
      getSignGifts(),
      getGiftList(),
    ]);
    if (this.active === 1 && (this.giftList.length || this.signGiftList.length)) {
      this.active = 0;
    }
  },
  methods: {
    async init() {
      this.orientation = window.orientation >= 0 ? window.orientation : Math.abs(window.orientation) + 90;
      const { game_code: gameCode } = this.$route.query;

      // 特殊逻辑
      // 这几款游戏，自动跳转到横版页面
      if (gameCode && autoJumpGameCode.includes(gameCode)) {
        this.$router.replace({
          path: '/download/horizontal',
          query: this.$route.query,
        });
        return;
      }
      window.addEventListener('resize', () => {
        this.orientation = window.orientation >= 0 ? window.orientation : Math.abs(window.orientation) + 90;
      });
      this.pageData = await getUserInfo();
      logEvent({ eventType: 1, extendId: 1, operId: '1901000010101' });
    },
  },
};
</script>

<style lang="scss">
$radio: 110;
//竖屏适配
@media screen and (max-width: 500px) {
  @function tpx($width) {
    @return 1.467 * $width/$radio * 1rem;
  }

  @import "./source/index";
  @import "./source/index-v";
}


//横屏
//横屏适配 根据自己的页面需求酌情调节比例

//iphone4,5/se


@media only screen and (min-width: 500px) and (max-width: 600px) {
  @function tpx($width) {
    @return 0.83 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

//iphone6,7,8  ,iphone6,7,8 plus   Galaxy S5 ,Nexus 6 6p, Microsoft , LG
@media only screen and (min-width: 601px) and(max-width: 1000px) {
  @function tpx($width) {
    @return $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

// Nexus 10, Laptop ,Kindle ire,
@media only screen and (min-width: 1001px) and (max-width: 1300px) {
  //ipad 以上
  @function tpx($width) {
    @return 1.7 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

//iPad Pro ,
@media only screen and (min-width: 1301px) and (max-width: 1600px) {
  //ipad 以上
  @function tpx($width) {
    @return 2 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}

@media only screen and (min-width: 1601px) {
  //ipad 以上
  @function tpx($width) {
    @return 2.5 * $width/$radio * 1rem;
  }
  @import "./source/index";
  @import "./source/index-h.scss";
}
</style>
